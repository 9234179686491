<template>
  <div class="mural-item" v-if="project">
    <figure>
      <span class="icon">
        <img
          src="@/assets/img/icon-image.svg"
          alt=""
          v-if="!project.videos.length"
        />
        <img src="@/assets/img/icon-play.svg" alt="" v-else />
      </span>
      <img
        :src="getYouTubeEmbedAndThumb(project.videos[0].link).thumbUrl"
        alt=""
        v-if="videoPlatform == 'youtube'"
      />
      <img
        :src="getThumbVimeo(project.videos[0].link)"
        alt=""
        v-else-if="videoPlatform == 'vimeo'"
      />
      <img
        :src="tiktokThumbnail"
        alt=""
        v-else-if="videoPlatform == 'tiktok'"
      />
      <img :src="project.images[0].path" alt="" v-else />
    </figure>
    <div class="mural-item__content">
      <div class="mural-item__content__wrapper">
        <h3>{{ project.title }}</h3>
        <p>
          <strong>Escola:</strong
          >{{ project.schools.map(school => school.name).join(", ") }}
        </p>
        <p><strong>Ano:</strong>{{ project.school_degree }}</p>
        <p><strong>Bioma:</strong>{{ project.biome }}</p>
      </div>
      <div class="mural-item__content__location">
        <p>
          <strong>{{ project.city }} - {{ project.state }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      videoPlatform: null,
      tiktokThumbnail: null,
    };
  },
  watch: {
    project: {
      immediate: true,
      handler(newValue) {
        if (newValue.videos.length) {
          this.detectPlatform(newValue.videos[0].link);
        }
      },
    },
  },
  methods: {
    detectPlatform(url) {
      if (this.youtubeCheck(url)) {
        this.videoPlatform = "youtube";
      } else if (this.vimeoCheck(url)) {
        this.videoPlatform = "vimeo";
      } else if (this.tiktokCheck(url)) {
        this.videoPlatform = "tiktok";
        this.loadTikTokThumbnail(url);
      } else if (this.facebookCheck(url)) {
        this.videoPlatform = "facebook";
      } else {
        this.videoPlatform = null;
      }
    },
    youtubeCheck(url) {
      const youtubeRegex =
        // eslint-disable-next-line no-useless-escape
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      return youtubeRegex.test(url);
    },
    vimeoCheck(url) {
      const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;
      return vimeoRegex.test(url);
    },
    getVimeoEmbedUrl(url) {
      const videoId = url.match(/vimeo\.com\/(\d+)/)[1];
      return `https://player.vimeo.com/video/${videoId}`;
    },
    tiktokCheck(url) {
      const tiktokRegex =
        /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(@[\w.-]+)\/video\/(\d+)/;
      return tiktokRegex.test(url);
    },
    getTikTokEmbedUrl(url) {
      const match = url.match(/tiktok\.com\/(@[\w.-]+)\/video\/(\d+)/);
      const videoId = match ? match[2] : null;
      return `https://www.tiktok.com/embed/${videoId}`;
    },
    getThumbVimeo(url) {
      const videoId = url.match(/vimeo\.com\/(\d+)/)[1];
      return `https://vumbnail.com/${videoId}.jpg`;
    },
    getYouTubeEmbedAndThumb(url) {
      const videoId = url.match(
        // eslint-disable-next-line no-useless-escape
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      )[1];
      if (videoId) {
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        const thumbUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

        return { embedUrl, thumbUrl };
      }
      return null;
    },

    async loadTikTokThumbnail(url) {
      this.tiktokThumbnail = await this.getTikTokThumbnail(url);
    },
    async getTikTokThumbnail(videoUrl) {
      const oembedUrl = `https://www.tiktok.com/oembed?url=${encodeURIComponent(
        videoUrl
      )}`;

      try {
        const response = await fetch(oembedUrl);
        const data = await response.json();

        if (data.thumbnail_url) {
          return data.thumbnail_url;
        } else {
          return this.project.images[0].path;
        }
      } catch (error) {
        console.error("Erro ao pegar thumbnail do TikTok:", error);
        return null;
      }
    },
    facebookCheck(url) {
      const facebookRegex =
        /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.*\/videos\/(\d+)/;
      return facebookRegex.test(url);
    },
  },
};
</script>

<style scoped lang="scss">
.mural-item {
  background: color(white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  figure {
    position: relative;
    margin-bottom: -4px;
    .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      @include media(">=desktop") {
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 7px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: RGBA(0, 0, 0, 0.5);
        transition: 0.3s ease-in-out;
        opacity: 0;
      }
      img {
        aspect-ratio: initial !important;
        width: 19px;
        @include media(">=desktop") {
          width: initial;
        }
      }
    }
    img {
      aspect-ratio: 308/250;
      object-fit: cover;
    }
  }
  &__content {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__wrapper {
      h3 {
        font-size: 1em;
        margin-bottom: 9px;
        word-break: break-word;
        @include media(">=desktop") {
          margin-bottom: 17px;
        }
        & + p {
          strong {
            &::before {
              display: none;
            }
          }
        }
      }
      p {
        font-size: 0.727em;
        margin-bottom: 4px;
        strong {
          font-size: 0px;
          color: color(pink);
          text-transform: uppercase;
          display: block;
          @include media(">=desktop") {
            font-size: initial;
          }
          &::before {
            content: "";
            width: 15px;
            height: 2px;
            display: block;
            background: color(pink);
            margin-bottom: 3px;
            @include media(">=desktop") {
              display: none;
            }
          }
        }
      }
    }
    &__location {
      border-top: 1px solid color(black);
      padding-top: 10px;
      margin-top: 10px;
      p {
        font-size: 0.818em;
      }
    }
  }
  &:hover {
    @include media(">=desktop") {
      figure {
        .icon {
          opacity: 1;
        }
      }
    }
  }
}
</style>

import { createStore } from "vuex";

export default createStore({
  state: {
    dropdowns: {
      biome: false,
      state: false,
      order: false,
    },
  },
  getters: {},
  mutations: {
    SET_CURRENT(state, { dropdown, value }) {
      state.dropdowns[dropdown] = value;
    },
    RESET_DROPDOWNS(state, dropdownsToReset) {
      dropdownsToReset.forEach(dropdown => {
        state.dropdowns[dropdown] = null;
      });
    },
  },
  actions: {
    updateDropdown({ commit, dispatch }, { dropdown, value }) {
      commit("SET_CURRENT", { dropdown, value });
      if (dropdown === "biome") {
        dispatch("resetOtherDropdowns", ["state"]);
      } else if (dropdown === "state") {
        dispatch("resetOtherDropdowns", ["biome"]);
      }
    },
    resetOtherDropdowns({ commit }, dropdownsToReset) {
      commit("RESET_DROPDOWNS", dropdownsToReset);
    },
  },
  modules: {},
});
